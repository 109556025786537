(<template>
  <div :class="{'tile-skill--read-only': !uid}"
       class="tile-skill__lang-skills-row">
    <div v-for="skill in skills"
         :key="skill.id"
         class="tile-skill__lang-skill-cont">
      <sk-radio v-if="userCanCreateDirectJobs && uid"
                :item-name="uid"
                :item-value="skill.id"
                :preselected-value="chosenSkill"
                class="tile-skill__skill-radio"
                @radiochanged="catchRadio"
                @click.stop.prevent="" />
      <p class="tile-skill__text"
         v-html="skillText(skill)"></p>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      skills: {
        type: Array,
        default: () => []
      },
      uid: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        chosenSkill: this.skills.length ? this.skills[0].id : ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanCreateDirectJobs'
      ]),
      showDivider() { return this.clientWidth > 767 ? '|' : ''; },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; }
    },
    methods: {
      catchRadio(value) {
        this.$emit('changeskill', value);
        this.chosenSkill = value;
      },
      phonePrice(skill) {
        for (const key of Object.keys(skill.prices)) {
          if (key == 'phone') return skill.prices[key];
        }
      },
      skillText(skill) {
        return `${skill.langFrom} - ${skill.langTo} ${this.showDivider} <span>${skill.qualification}</span>`;
      }
    }
  };
</script>

<style>
  .inter-tile .tile-skill__skill-radio.tile-skill__skill-radio {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .tile-skill__text span {
    color: #989898;
  }

  @media (max-width: 767px) {
    .tile-skill__text span {
      display: block;
      width: 100%;
    }

    .inter-tile .tile-skill__skill-radio.tile-skill__skill-radio {
      position: absolute;
      top: 10px;
      left: 0;
      margin-top: 10px;
      transform: translateY(0);
    }
  }
</style>

<style scoped>
  .tile-skill__lang-skills-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .tile-skill__lang-skill-cont {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 30px;
  }

  .tile-skill--read-only .tile-skill__lang-skill-cont {
    padding-left: 0;
  }

  @media (max-width: 767px) {
    .tile-skill__lang-skills-row {
      width: 100%;
    }

    .tile-skill__lang-skill-cont {
      display: block;
      width: 100%;
      margin-bottom: 0;
      padding: 10px 0 5px 25px;
      border-top: 1px solid #e9eaef;
    }

    .tile-skill__lang-skill-cont:last-child {
      margin-bottom: 10px;
    }
  }
</style>
