(<template>
  <section class="my-lang">
    <div class="my-lan__cont">
      <p class="my-lang__text">{{ $gettext('My language:') }}</p>
      <sk-select :type="'language_from'"
                 :default-value="text1"
                 :removed-item="langTo"
                 :preselected-value="langFrom"
                 class="sk-select--lang my-lang__select"
                 @csvaluechanged="changeLangFrom" />
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      langFrom: {
        type: [String, Number],
        default: ''
      },
      langTo: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      text1() { return this.$gettext('Select'); }
    },
    methods: {
      changeLangFrom(value) {
        this.$emit('langchosen', value);
      }
    }
  };
</script>

<style scoped>
  .my-lang {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 15px;
  }

  .my-lan__cont {
    display: flex;
    align-items: center;
    color: #646d8c;
    font-size: 12px;
  }

  .my-lang__select {
    width: 80px;
  }

  .my-lang__text {
    padding-right: 5px;
    text-align: right;
  }

  @media (max-width: 767px) {
    .my-lang {
      padding: 0 15px 0;
    }

    .my-lan__cont {
      margin-left: auto;
    }
  }
</style>
