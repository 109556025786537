// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/checkboxes_icons/heart-checkbox-selected-logo_red-hover.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/checkboxes_icons/heart-checkbox-logo_gray.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.inter-tile__rate .rate__star.rate__star {\n  width: 14px;\n  height: 14px;\n  margin-right: 2px;\n  background-size: 14px auto;\n}\n.inter-tile__favor-btn.add-favourite {\n  display: block;\n}\n.inter-tile__blocked-status .interpreter-blocked-status__tooltip-btn {\n  width: 20px;\n  height: 20px;\n}\n@media (max-width: 767px) {\n.inter-tile__favor-btn .add-favourite__btn {\n    width: 23px;\n    height: 23px;\n    padding: 0;\n    border: none;\n    background-position: 0 0;\n    background-size: 23px auto;\n}\n.inter-tile__favor-btn .add-favourite__btn-text {\n    display: none;\n}\n.inter-tile__favor-btn .add-favourite__btn:hover,\n  .inter-tile__favor-btn .add-favourite__btn.is-favourite,\n  .inter-tile__favor-btn .add-favourite__btn.is-favourite:hover {\n    border: none;\n    background-color: transparent;\n}\n.inter-tile__favor-btn .add-favourite__btn.is-favourite {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-image: var(--image-heart-hover-icon);\n}\n.inter-tile__favor-btn .add-favourite__btn:hover {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n}\n", ""]);
// Exports
module.exports = exports;
