(<template>
  <router-link :to="profileLink"
               :class="{'in-progress': progressActive}"
               class="inter-tile is-progress-bar">
    <div class="inter-tile__content-wrapper">
      <div :class="{'is-blocked': person.relation == 'blocked',
                    'is-attested-by-police attested-by-police-icon': person.showPoliceLogo,
                    'is-attested-by-police attested-by-police-temporary-icon': person.showTemporaryPoliceLogo}"
           class="inter-tile__avatar-cont">
        <tt-avatar :avatar="person.avatar"
                   class="inter-tile__avatar" />
        <interpreter-blocked-status v-if="isBlockedByStatus"
                                    :interpreter-data="person"
                                    class="inter-tile__blocked-status" />
      </div>
      <div class="inter-tile__person-info-row">
        <div class="inter-tile__name-wrapper">
          <div class="inter-tile__name">
            {{ person.name }}
            <button v-if="$isGodmode()"
                    class="sk-link sk-link--default"
                    @click="loginAsInterpreter">{{ $gettext('[T]') }}</button>
          </div>
          <p class="inter-tile__address">{{ computedGender }} {{ computedAddress }}</p>
          <div class="inter-tile__rate-cont">
            <rating :rate="averageRating"
                    class="inter-tile__rate" />
            <p class="inter-tile__reviews">{{ reviewsText }}</p>
          </div>
        </div>
        <add-to-favourite :person="person"
                          class="inter-tile__favor-btn"
                          @startprogress="progressActive = true"
                          @relationchanged="onRelationChanged" />
        <dirbook-btn v-if="userCanCreateDirectJobs"
                     :inter-uid="person.uid"
                     :is-blocked="isBlockedInterpreter"
                     class="inter-tile__direct-book-btn" />
      </div>
      <skills :skills="handledSkills" />
    </div>
    <dirbook-btn v-if="userCanCreateDirectJobs"
                 :inter-uid="person.uid"
                 :is-blocked="isBlockedInterpreter"
                 class="inter-tile__direct-book-btn inter-tile__direct-book-btn--mobile" />
  </router-link>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import Rating from '@/components/shared_components/Rating';
  import Skills from '@/components/interpreters/InterpretersTileSkill';
  import AddToFavourite from '@/components/shared_components/AddToFavourite';
  import DirbookBtn from '@/components/profile_components/direct_booking/ProfileDirbookBtn';
  import InterpreterBlockedStatus from '@/components/interpreters_components/InterpreterBlockedStatus';

  export default {
    components: {
      rating: Rating,
      skills: Skills,
      'add-to-favourite': AddToFavourite,
      'dirbook-btn': DirbookBtn,
      'interpreter-blocked-status': InterpreterBlockedStatus
    },
    props: {
      person: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userCanCreateDirectJobs']),
      statsInfo() { return this.person.stats || {}; },
      averageRating() { return this.statsInfo.averageRating || 0; },
      reviewsCount() { return this.statsInfo.reviewsCount || 0; },
      reviewsText() {
        return this.$gettextInterpolate(this.$gettext('%{ n } reviews'), {n: this.reviewsCount});
      },
      isBlockedByStatus() {
        return this.$isGodmode()
          && helpers.interpreterInfo.іsBlockedInterpreter(this.person);
      },
      isBlockedInterpreter() {
        return this.person.relation == 'blocked';
      },
      computedAddress() {
        if (this.person.city || this.person.country) {
          if (this.person.city && !this.person.country) {
            return `${this.person.city}`;
          } else if (!this.person.city && this.person.country) {
            return `${this.person.country}`;
          } else if ((this.person.city && this.person.country)) {
            return `${this.person.city}, ${this.person.country}`;
          }
        } else {
          return '';
        }
      },
      computedGender() {
        let gender = '';
        if ((this.person.sex || this.person.sex === 0) && this.$helperData.getSelectGenders(this) && this.$helperData.getSelectGenders(this).length) {
          for (const g of this.$helperData.getSelectGenders(this)) {
            if (this.person.sex == g.id) {
              gender = g.name;
            }
          }
        }
        if (this.person.city || this.person.country) {
          return `${gender} |`;
        } else {
          return gender;
        }
      },
      profileLink() {
        return this.$makeRoute({name: 'BuyerUserProfile', params: {id: this.person.uid}});
      },
      handledSkills() {
        const res = [];
        const skills = this.person.skills;
        const langs = this.$store.state.selectsData.language;
        const qualifications = this.$store.state.selectsData.qualification;
        if (skills
          && skills.length
          && langs
          && langs.length
          && qualifications
          && qualifications.length) {
          for (const skill of skills) {
            const obj = {};
            for (const lang of langs) {
              if (lang.id == skill.langFromId) {
                obj.langFrom = lang.name;
              } else if (lang.id == skill.langToId) {
                obj.langTo = lang.name;
              }
            }
            for (const qual of qualifications) {
              if (qual.id == skill.qualificationId) {
                obj.qualification = qual.name;
                break;
              }
            }
            obj.id = skill.id;
            obj.prices = skill.prices;
            res.push(obj);
          }
        }
        return res;
      }
    },
    methods: {
      onRelationChanged(value) {
        this.progressActive = false;
        this.$store.commit('InterpretersStore/setNewRelation', {uid: this.person.uid, value});
      },
      loginAsInterpreter() {
        const {id: userId} = this.person?.person;
        const {uid: userUid} = this.person;
        const params = {
          uid: userUid,
          admin_uid: this.$store.state.UserInfoStore.adminInfo.uid,
          notifs: false
        };

        this.$store.dispatch('TTAuthStore/getGodModeToken', params).then((data) => {
          const link = helpers.getGodmodeLoginLink({
            userId,
            userUid,
            token: data.token,
            jobId: this.jobId
          });
          window.open(link, '_blank');
        });
      }
    }
  };
</script>

<style>
  .inter-tile__rate .rate__star.rate__star {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    background-size: 14px auto;
  }

  .inter-tile__favor-btn.add-favourite {
    display: block;
  }

  .inter-tile__blocked-status .interpreter-blocked-status__tooltip-btn {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 767px) {
    .inter-tile__favor-btn .add-favourite__btn {
      width: 23px;
      height: 23px;
      padding: 0;
      border: none;
      background-position: 0 0;
      background-size: 23px auto;
    }

    .inter-tile__favor-btn .add-favourite__btn-text {
      display: none;
    }

    .inter-tile__favor-btn .add-favourite__btn:hover,
    .inter-tile__favor-btn .add-favourite__btn.is-favourite,
    .inter-tile__favor-btn .add-favourite__btn.is-favourite:hover {
      border: none;
      background-color: transparent;
    }

    .inter-tile__favor-btn .add-favourite__btn.is-favourite {
      background-image: url(~@assets/imgs/checkboxes_icons/heart-checkbox-selected-logo_red-hover.svg);
      background-image: var(--image-heart-hover-icon);
    }

    .inter-tile__favor-btn .add-favourite__btn:hover {
      background-image: url(~@assets/imgs/checkboxes_icons/heart-checkbox-logo_gray.svg);
    }
  }
</style>

<style scoped>
  .inter-tile {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: 175px;
    padding: 20px;
    padding-left: 100px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .inter-tile:first-child ~ .inter-tile {
    margin-top: 10px;
  }

  .inter-tile__content-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .inter-tile__avatar-cont {
    position: absolute;
    top: 26px;
    left: 20px;
    display: block;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
  }

  .inter-tile__blocked-status {
    position: absolute;
    top: 0;
    right: 0;
  }

  .inter-tile__avatar-cont.is-attested-by-police::after {
    right: 0;
    width: 30px;
    height: 30px;
  }

  .inter-tile__avatar-cont.is-blocked::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/block_inter_icon.svg);
    background-position: 50% 50%;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .inter-tile__avatar {
    width: 100%;
    height: 100%;
  }

  .inter-tile__person-info-row {
    display: flex;
    width: 100%;
    padding-bottom: 10px;
  }

  .inter-tile__direct-book-btn {
    flex-shrink: 0;
    width: auto;
    margin-left: 20px;
    padding: 0 25px;
  }

  .inter-tile__direct-book-btn--mobile {
    display: none;
  }

  .inter-tile__name-wrapper {
    width: 50%;
    margin-right: auto;
  }

  .inter-tile__name {
    font-weight: bold;
    font-size: 18px;
  }

  .inter-tile__address {
    font-size: 10px;
  }

  .stars-mock {
    display: block;
    width: 100px;
    height: 16px;
    margin-top: 5px;
    border: 1px solid red;
  }

  .inter-tile__rate-cont {
    display: flex;
    align-items: flex-start;
    margin: 5px 0;
  }

  .inter-tile__reviews {
    padding: 0 4px;
    font-size: 12px;
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    .inter-tile {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      min-height: initial;
      padding-right: 15px;
      padding-left: 15px;
      border-radius: 0;
    }

    .inter-tile:first-child ~ .inter-tile {
      margin-top: 20px;
    }

    .inter-tile__name-wrapper {
      overflow: auto;
      width: 100%;
      margin-right: 5px;
    }

    .inter-tile__avatar-cont {
      position: relative;
      top: 0;
      left: 0;
      flex-shrink: 0;
      height: 70px;
      padding-bottom: 10px;
    }

    .inter-tile__person-info-row {
      flex-grow: 1;
      width: calc(100% - 60px);
      padding-right: 0;
      padding-bottom: 10px;
      padding-left: 20px;
    }

    .inter-tile__direct-book-btn {
      display: none;
    }

    .inter-tile__direct-book-btn--mobile {
      display: block;
      width: 240px;
      margin: 0 auto;
    }
  }
</style>
